.header-fixed {
  z-index: 1;
}

notification-bar {
  display: none !important;
}

.p-dialog-visible {
  z-index: 10000 !important;
}

.p-dialog-visible:has(.create-user-attribute-dialog) {
  z-index: 998 !important;
}

.report-page {
  margin-top: -24px;
  margin-left: -30px;
  margin-right: -30px;
  height: calc(100vh - 108px);
}

.p-datatable-tbody {
  word-break: break-all;
}

.p-dialog-content {
  max-height: 75vh;
}

.p-radiobutton {
  margin-top: 1px;
  margin-right: 4px;
}

iframe {
  border: none;
}

.p-dialog-wrapper-visible {
  z-index: 10000 !important;
}

.card-header {
  font-weight: bold;
  font-size: 16px;
}

.p-datatable-header {
  min-height: 76px;
}

.dialog-disable-overlay {
  position: relative;
}
.dialog-disable-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  opacity: 0.5;
  z-index: 2;
}

ol > li::marker {
  font-weight: bold;
}

#popup_review-template,
#change-question-type-menu,
#change-visibility-type-menu {
  top: 40px !important;
  left: unset !important;
}
#add-question-menu {
  top: -132px !important;
  left: unset !important;
}

.p-galleria-mask.p-galleria-visible.p-component-overlay {
  z-index: 1020 !important;
}
