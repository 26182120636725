.badgeInputTitle {
  font-weight: bold;
}
p {
  margin-bottom: 0.3rem;
}

.header-fixed {
  z-index: 1;
}

.settingsRow {
  margin-bottom: 8px;
  min-height: 35px;
}

.p-datatable-scrollable-header-box {
  margin-right: 0 !important;
}

.RichEditor-root {
  font-size: 12px;
  position: relative;
}

.RichEditor-editor {
  cursor: text;
  font-size: 12px;
}
.DraftEditor-editorContainer {
  display: flex;
  justify-content: center;
}

.public-DraftEditor-content {
  width: 100%;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 3px;
}
.public-DraftEditor-content:hover {
  border-color: #212121;
}

.public-DraftEditor-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: var(--focus-ring);
  box-shadow: var(--focus-ring);
  border-color: var(--primary-color);
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 0.429rem;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Segoe UI', system-ui, 'Apple Color Emoji', 'Segoe UI Emoji', sans-serif;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.rich-editor-buttons {
  display: flex;
  flex-direction: row;
}

.RichEditor-styleButton {
  color: #505050;
  cursor: pointer;
  width: 14px;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  &.RichEditor-activeButton {
    & svg {
      fill: rgb(98, 100, 167) !important;
    }
  }
  &:hover {
    & svg {
      fill: rgb(98, 100, 167) !important;
    }
  }
}

.onHover {
  cursor: pointer;
}

.RichEditor-activeButton {
  color: rgb(98, 100, 167) !important;
}

.RichEditor-root.dark {
  .public-DraftEditor-content {
    background-color: #282727;
  }
  .RichEditor-styleButton {
    color: #fff;
  }
  .RichEditor-blockquote {
    border-left: 5px solid #666;
    color: #eee;
  }
  .public-DraftStyleDefault-pre {
    background-color: #505050;
  }
}

.RichEditor-editor {
  h1 {
    font-size: 2em;
    font-weight: 600;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 0.83em 0;
  }
  h3 {
    font-size: 1.3em;
    font-weight: 600;
    margin: 1em 0;
  }
  h4 {
    font-size: 1em;
    font-weight: 600;
    margin: 1.33em 0;
  }
  h5 {
    font-size: 0.8em;
    font-weight: 600;
    margin: 1.67em 0;
  }
  h6 {
    font-size: 0.7em;
    font-weight: 600;
    margin: 2.33em 0;
  }
}

.sidebar {
  z-index: 997 !important; // 998 is the smallest z-index for a modal, and it should be on top of the sidebar
  .nav-link.active {
    .nav-icon {
      color: white;
    }
  }
}

.p-dropdown-panel {
  z-index: 99999999999 !important;
}

.p-datepicker {
  z-index: 99999999999 !important;
}

.Toastify__toast-container {
  z-index: 99999999999 !important;
}

.font-size-xl {
  font-size: 1.2em;
}

.font-size-l {
  font-size: 1em;
}

.font-size-m {
  font-size: 0.9em;
}

.font-size-s {
  font-size: 0.8em;
}

.calendly-popup {
  max-height: none !important;
}

.tcsBoxContainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-bottom: 8px;
  .tcsBox {
    display: flex;
    cursor: pointer;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    background: #fff;
    .tcsInnerContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .tcsContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        .tcsBoxHeaderText {
          color: #313131;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          &.tcsTextDisabled {
            color: #9a9a9a;
          }
        }
        .tcsBoxBody {
          color: #313131;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .tcsRadioButton {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.tcsBoxContainerListView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .tcsBoxListView {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    .tcsInnerContainerListView {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;
      &.withToggle {
        cursor: default;
      }
      .tcsContentListView {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        .tcsBoxHeaderTextListView {
          color: #313131;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          &.tcsTextDisabled {
            color: #9a9a9a;
          }
        }
      }
    }
  }
}

// If we want to change how the current scrollbars look, we can use the following code

// .addQuestionSetFromLibraryModal {
//   .p-dialog-content {
//     &::-webkit-scrollbar {
//       width: 8px;
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: #888;
//       border-radius: 10px;
//       &:hover {
//         background-color: #555;
//       }
//     }
//     &::-webkit-scrollbar-track {
//       background-color: #fff;
//     }
//     /* For Firefox */
//     scrollbar-width: thin;
//     scrollbar-color: #888 #fff;
//   }
// }

.addQuestionSetFromBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 5px;
  .questionSetSearchBarContainer {
    width: 100%;
    .p-inputtext:enabled:focus {
      border-color: #ced4da;
      box-shadow: unset;
    }
  }
  .questionSetInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    color: #b37730;
  }
  .questionSetBoxesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    .questionSetBox {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      .questionSetBoxTextContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        color: #313131;
        width: 80%;
        .questionSetBoxLabel {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          align-self: stretch;
        }
        .questionSetBoxQuestionCount {
          align-self: stretch;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .questionSetBoxDescription {
          color: #505050;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
      .questionSetBoxAddButtonContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
      }
    }
    .questionSetEmptyText {
      color: #505050;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.questionSetDisplayContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  .questionSetDisplayItemContainer {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #e3e3e3;
    cursor: grab;
    &.noDraggable {
      cursor: default;
    }
    &.dragging {
      cursor: grabbing;
    }
    .reorderIcon {
      flex-shrink: 0;
    }
    .questionSetDisplayItemTextContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      width: 80%;
      .questionSetDisplayItemLabel {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        align-self: stretch;
        color: #313131;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      .questionSetDisplayItemQuestionCount {
        align-self: stretch;
        color: #313131;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .questionSetDisplayItemDescription {
        color: #505050;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .questionSetDisplayItemButtonContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  }
  &.bubbleDisplay {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: row;
    .questionSetDisplayItemContainer {
      width: fit-content;
      max-width: 300px; /* Define a max width for bubbles */
      flex-wrap: wrap;
    }
  }
}

.templateSectionToggleArea {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  background: #ecedf4;
  .templateSectionToggleAreaTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    .templateSectionToggleAreaMainText {
      color: #313131;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
    .templateSectionToggleAreaBottomText {
      color: #313131;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.goalRelatedQuestionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  .goalRelatedQuestionsBodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    .goalRelatedQuestionsSectionTitleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .goalRelatedQuestionsSectionTitleLabel {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    .goalRelatedQuestionsDescriptionContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .goalRelatedQuestionsDescriptionLabel {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    .goalRelatedQuestionsQuestionTypeContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .goalRelatedQuestionsQuestionTypeTextContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;
        .goalRelatedQuestionsQuestionTypeMainText {
          color: #313131;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        .goalRelatedQuestionsQuestionTypeBottomText {
          color: #313131;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .goalRelatedQuestionsGoalPeriodsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .goalRelatedQuestionsGoalPeriodsText {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .goalRelatedQuestionsGoalPeriodsBottomTextContainer {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        margin-top: -8px;
        .goalRelatedQuestionsGoalPeriodsBottomText {
          color: #444691;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
        }
      }
    }
    .goalFiltersContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .goalFiltersText {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .goalFiltersSections {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        .goalFilterBox {
          display: flex;
          padding: 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #e3e3e3;
          .goalFilterTextContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;
            .goalFilterText {
              color: #313131;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.choicesGroupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .choicesText {
    color: #313131;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  .choicesContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
  }
  .choicesCheckboxesContainer {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
}

.competencyBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .competencySectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .competencySectionTitleHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .competencySectionTitleHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .competencySectionDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .competencySectionDescriptionHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .competencySectionDescriptionHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .competencySectionAnswerTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .competencySectionAnswerTypeHeader {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
  .competencyLastSettingsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .competencyWillBeRespondedByContainer {
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      .competencyWillBeRespondedByText {
        color: #313131;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    .competencyMakeCompetencyQuestionsMandatoryContainer {
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      .competencyMakeCompetencyQuestionsMandatoryText {
        color: #313131;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

.add360DegreeFeedbackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .add360DegreeFeedbackSectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .add360DegreeFeedbackSectionTitleHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .add360DegreeFeedbackSectionTitleText {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .add360DegreeFeedbackSectionInstructionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .add360DegreeFeedbackSectionInstructionsHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .add360DegreeFeedbackSectionInstructionsText {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .add360DegreeFeedbackAskFeedbackFromContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .add360DegreeFeedbackAskFeedbackFromTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
}

.p-card-without-footer-padding {
  .p-card-footer {
    padding: 0 !important;
  }
}

.feedback360DegreeItemForReviewsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  .feedback360DegreeSectionTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .feedback360DegreeSectionTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
  .feedback360DegreeItemForReviewsAddButtons {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  @media (max-width: 1360px) {
    .feedback360DegreeItemForReviewsAddButtons {
      flex-direction: column;
    }
  }
  .feedback360DegreeItemForReviewsTemplateSelectionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .feedback360DegreeQuestionSetDisplayContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }
  .feedback360DegreeItemForReviewsTitle {
    color: #313131;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  .feedback360DegreeItemForReviewsNumberOfProvidersContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .feedback360DegreeItemForReviewsNumberOfProvidersTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .feedback360DegreeItemForReviewsMinMaxContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      .feedback360DegreeItemForReviewsMinMaxInnerContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        .feedback360DegreeItemForReviewsMinMaxLabel {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
  .feedback360DegreeItemForReviewsFeedbackVisibilityAndWhoCanAskContainer {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    .feedback360DegreeItemForReviewsFeedbackVisibilityContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      .feedback360DegreeItemForReviewsFeedbackVisibilityTitle {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .feedback360DegreeItemForReviewsFeedbackVisibilityBottomContainer {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        color: #9a9a9a;
        .feedback360DegreeItemForReviewsFeedbackVisibilityBottomText {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
    .feedback360DegreeItemForReviewsWhoCanAskContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      .feedback360DegreeItemForReviewsWhoCanAskTitle {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .feedback360DegreeItemForReviewsWhoCanAskCheckboxContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
      }
    }
  }
  @media (max-width: 1360px) {
    .feedback360DegreeItemForReviewsFeedbackVisibilityAndWhoCanAskContainer {
      flex-direction: column;
    }
  }
}

.arrowButtonsNotVisible {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.disabledText {
  color: #9a9a9a !important;
}

.reviewResultContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .reviewResultSectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .reviewResultSectionTitleHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      .reviewResultSectionTitleText {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .reviewResultSectionInstructionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .reviewResultSectionInstructionsTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
  .reviewResultSectionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .reviewResultSectionsTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .scoreCriterionContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      .scoreCriterionMainPartContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        .scoreCriterionButtonContainer {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          margin-top: 8px;
        }
        @media (max-width: 1360px) {
          .scoreCriterionButtonContainer {
            flex-direction: column;
          }
        }
        .scoreCriterionBottomTextContainer {
          display: flex;
          padding-right: 52px;
          align-items: center;
          gap: 16px;
          margin-top: 16px;
          align-self: stretch;
          .scoreCriterionDistributeWeightsEvenly {
            color: #444691;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          .scoreCriterionWarningTextContainer {
            display: flex;
            align-items: center;
            gap: 2px;
            color: #ff1606;
            .scoreCriterionWarningText {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
        .scoreCriterionOutOfSectionContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          margin-top: 24px;
          .scoreCriterionOutOfSectionTitle {
            color: #313131;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
          }
          .scoreCriterionOutOfSectionButtonsContainer {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .p-button,
            .p-inputtext {
              flex: 1 1;
              min-width: 40px;
            }
          }
        }
        .scoreCriterionRatingSectionVisibilityContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          margin-top: 24px;
          .scoreCriterionRatingSectionVisibilityText {
            color: #313131;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
          }
        }
      }
    }
    .choiceCriterionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      .choiceCriterionMainPartContainer {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #e3e3e3;
        .choiceCriterionInfoContainer {
          display: flex;
          align-items: flex-end;
          gap: 8px;
          align-self: stretch;
          .choiceCriterionNameContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
          }
        }
        .choiceCriterionChoicesContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          .choiceCriterionChoicesTitle {
            color: #313131;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
          }
          .choiceCriterionChoiceContainer {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            width: 100%;
          }
        }
      }
    }
    .nineBoxSectionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      .nineBoxSectionInstructionsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        .nineBoxSectionInstructionsText {
          color: #313131;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
      }
      .nineBoxSectionNineBoxContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-top: 24px;
        align-self: stretch;
        .nineBoxSectionNineBoxText {
          color: #313131;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        .nineBoxSectionNineBoxCheckboxContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          margin-top: 8px;
        }
      }
    }
  }
}

.developmentPlanContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .developmentPlanSectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .developmentPlanSectionTitleHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .developmentPlanSectionTitleHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .developmentPlanSectionInstructionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .developmentPlanSectionInstructionsHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .developmentPlanSectionInstructionsHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.attachmentsPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .attachmentsPageSectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .attachmentsPageSectionTitleHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .attachmentsPageSectionTitleHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .attachmentsPageSectionInstructionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .attachmentsPageSectionInstructionsHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .attachmentsPageSectionInstructionsHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.signatureSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .signatureSectionTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .signatureSectionTitleHeaderContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      .signatureSectionTitleHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
  .signatureSectionDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .signatureSectionDescriptionHeader {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
  .signatureSectionSignatureTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .signatureSectionSignatureTypeHeader {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .signatureSectionSignatureOrderContainer {
      display: flex;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      .signatureSectionSignatureOrderHeader {
        color: #313131;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

.finalizationFlowContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  .finalizationFlowTitle {
    color: #313131;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  .calibrationOnExtraContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .calibrationOnExtraExplanation {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      .calibrationOnExtraExplanationText {
        color: #505050;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .calibrationOnExtraWhoFinalizesContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .calibrationOnExtraWhoFinalizesTitle {
        color: #313131;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .calibrationOnExtraWhoFinalizesRadioButtonContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .calibrationOnExtraWhoFinalizesRadioButtonLabel {
          margin-left: 8px;
        }
      }
    }
  }
}

.summaryPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  .summaryPageItemContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .summaryPageItemTitle {
      color: #313131;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
    .summaryPageItemValue {
      color: #313131;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.inputBottomInfoText {
  color: #9a9a9a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
