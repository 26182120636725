.hide-until-700px {
  @media (max-width: 700px) {
    display: none !important;
  }
}

.hide-until-500px {
  @media (max-width: 500px) {
    display: none !important;
  }
}
